<template>
  <div class="search">
    <!-- 头部 -->
    <section class="sheader dark_bg">
      <p class="iconfont icon-fanhui-copy" @click="goBack"></p>
      <!-- 输入框 -->
      <form action="/">
        <van-search
          v-model="value"
          placeholder="请输入搜索关键词"
          show-action
          shape="round"
          @input="inputHandle"
          @focus="inputHandle"
          class="dark_bg"
        >
          <div slot="action" @click="searchHandle" v-if="!searchList.length" class="dark_text">搜索</div>
          <!-- 功能直达 -->
          <fast slot="action" v-else></fast>
        </van-search>
      </form>
      <!-- 搜索提示 -->
      <ul class="search-result dark_bg" v-if="autoShow&&autoData.length" >
        <li v-for="result in autoData" :key="result.id" @click="searchAuto(result)" class="dark_bg">
          <p class="dark_text">{{result.title}}</p>
          <span class="iconfont icon-zuoshangjiao"></span>
        </li>
      </ul>
    </section>

    <div class="search-body">
      <!-- 历史搜索 -->
      <!-- <section class="search-history" v-if="!searchList.length">
        <p>
          <span class="dark_search_text">历史搜索</span>
          <em class="iconfont icon-shanchu dark_search_text" @click="delHistory"></em>
        </p>
        <ul>
          <li
            v-for="item in historyList"
            :key="item.id"
            @click="searchHistory(item.description)"
            class="dark_shop_bg dark_search_text"
          >{{item.description}}</li>
        </ul>
      </section> -->
      <!-- 产品展示 -->
      <div class="search-goods" v-show="showGoods">
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="finishText"
          @load="onLoad"
          :error.sync="error"
          offset:100
        >
          <van-card
            :title="item.title"
            :desc="item.description"
            :thumb="item.img"
            v-for="item in searchList"
            :key="item.id"
            :lazy-load="true"
            @click="goGoodsDetail(item.id)"
          >
            <!-- <p slot="price" style="font-size:.32rem;color:#dd4f4f">￥{{item.price / 100}}</p> -->
            <p slot="price" >￥{{item.showPrice}}</p>
            <!-- <p slot="origin-price" v-if="item.discountMoney">￥{{item.minMoney}}</p> -->
            <p
              slot="bottom"
              style="color:#dd4f4f;font-size:.22rem;letter-spacing:1px;"
              v-if="item.score"
            >{{item.score}}积分</p>
          </van-card>
          <!-- <p slot="loading">
            <img
              src="../../assets/images/index.messenger-typing-preloader.svg"
              alt
              style="width:20%;"
            />
          </p> -->
          <loading-infinity slot="loading"></loading-infinity>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Row,
  Col,
  List,
  DropdownMenu,
  DropdownItem,
  NumberKeyboard,
  Search,
  Card
} from "vant";
import Fast from "@/components/public/directFunc";
let accessToken = localStorage.getItem("token");
export default {
  name: "search",
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [List.name]: List,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Search.name]: Search,
    [Card.name]: Card,
    Fast
  },
  data() {
    return {
      value: "",
      historyList: [],
      delShow: false,
      searchList: [],
      loading: false,
      finished: true,
      error: false,
      pageNo: 1,
      totalPage: Number,
      finishText: "",
      autoData: [],
      showGoods: true,
      autoShow: false,
      goGoodsDetail: this.Base.goGoodsDetail
    };
  },
  created() {
    // this.readHistory();
  },
  activated() {
    // isUseCache为false时才重新刷新获取数据
    // 因为对list使用keep-alive来缓存组件，所以默认是会使用缓存数据的
    if (!this.$route.meta.isUseCache) {
      this.searchList = [];
      this.finishText = "";
      this.value = "";
      // this.readHistory();
      // 自动聚焦
      this.focusSelf();
    }

    // 通过这个控制刷新
    this.$route.meta.isUseCache = false;
  },
  methods: {
    // 自动聚焦
    focusSelf() {
      this.$nextTick(e => {
        // 获取搜索框
        let inputs = document.querySelector("input");
        // 让搜索框自动聚焦
        inputs.focus();
      });
    },
    // 查询历史
    readHistory() {
      this.$api.home
        .historyAddress({
          accessToken
        })
        .then(res => {
          if (res.code === 100) {
            this.historyList = res.returnValue;
          }
        });
    },
    clearValue(){
      console.log(8989);
      this.value = ''
    },
    // 删除历史
    delHistory() {
      if (this.historyList.length) {
        this.historyList = [];
        this.$api.home
          .delhistoryAddress({
            accessToken
          })
          .then(res => {
            if (res.code === 100) {
              this.historyList = [];
            }
          });
      }
    },
    // 输入为空提示
    emptyTip() {
      this.finished = true;
      this.loading = false;
      this.$toast({
        message: "请输入要搜索的内容",
        duration: 800
      });
    },
    // 新增历史
    addHistory() {
      if (this.value === "") {
        this.emptyTip();
        return;
      }
      this.$api.home
        .addHistoryAddress({
          accessToken,
          description: this.value
        })
        .then(res => {});
    },
    // enter键搜索 发起请求 @keyup.enter='enterSearch'
    searchHandle() {
      this.searchList = [];
      this.pageNo = 1;
      this.totalPage = Number;
      this.loading = true;
      this.finished = false;
      this.autoShow = false;
      // 带着this.value去搜索
      // this.addHistory();
      this.searchGoods();
    },
    // 搜索商品
    searchGoods() {
      this.finishText = this.$globalConfig.finishedText;
      if (this.pageNo > this.totalPage) {
        this.loading = false;
        // 恢复pageNo和totalPage的值，以供分类分页使用
        return (this.finished = true);
      }
      const title = this.value;
      if (title === "") {
        this.emptyTip();
        this.autoShow = false;
        // 如果为空，不展示搜索结果面板
        return;
      }
      this.$api.home
        .searchGoodsListAddress({
          title,
          pageNo: this.pageNo,
          storeId:localStorage.storeId
        })
        .then(res => {
          if (res.errno === 200) {
            this.showGoods = true;
            this.totalPage = res.data.totalPageNo;
            this.loading = false; // 关闭上拉加载中
            if (this.pageNo === 1) {
              this.searchList = res.data.items;
            } else {
              this.searchList = this.searchList.concat(res.data.items);
            }
            this.pageNo++;
          } else {
            this.error = true;
          }
        });
    },
    // 搜索框的内容
    inputHandle() {
      this.delShow = true;
      this.autoData.length = 0;
      this.autoShow = true;
      this.searchList = [];
      if (this.value === "") {
        // 如果为空，不展示搜索结果面板
        this.autoShow = false;
        return;
      }
      this.$api.home
        .completeSearchAddress({
          title: this.value,
          storeId:localStorage.storeId
        })
        .then(res => {
          this.autoData = res.data.items;
        });
    },
    // 删除搜索框内容  发后台存数据库
    delValue() {
      this.delShow = false;
      this.value = "";
      this.autoShow = false;
    },
    // 再次搜索历史
    searchHistory(value) {
      this.searchList = [];
      this.pageNo = 1;
      this.totalPage = Number;
      this.finished = false;
      this.delShow = true;
      this.value = value;
      // 带着this.value发起请求
      // this.addHistory();
      this.searchGoods();
    },
    // 从模糊显示中点击搜索
    searchAuto(value) {
      this.searchList = [];
      this.pageNo = 1;
      this.totalPage = Number;
      this.finished = false;
      this.autoShow = false;
      this.value = value.title;
      // 带着this.value去搜索
      // this.addHistory();
      this.searchGoods();
    },
    // 上拉加载
    onLoad() {
      // 异步获取数据
      setTimeout(() => {
        this.searchGoods();
      }, 1000);
    },
    goBack() {
      this.$router.back(0);
    }
  },
  // 搜索页面的beforeRouteLeave钩子函数
  beforeRouteLeave(to, from, next) {
    if (to.name === "detail" || to.name === "login") {
      from.meta.isUseCache = true;
    }
    next();
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/styles/element.less";
@red: #d84b41;
.search {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: .96rem /* 48/50 */;
  // background: #f0f0f0;
  .sheader {
    width: 100%;
    box-sizing: border-box;
    padding-left: .32rem /* 16/50 */;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > form {
      flex: 1;
    }
    .search-result {
      position: absolute;
      top: .96rem /* 48/50 */;
      left: 0;
      width: 100%;
      background-color: #fff;
      color: #8b8989;
      z-index: 99;
      box-sizing: border-box;
      font-size: .24rem /* 12/50 */;
      padding: .32rem /* 16/50 */ .24rem /* 12/50 */;
      border-radius: 0 0 .32rem /* 16/50 */ .32rem /* 16/50 */;
      li {
        line-height: .38rem /* 19/50 */;
        padding: .12rem /* 6/50 */ 0;
        border-bottom: 1px solid #f5f5f5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          font-size: .38rem /* 19/50 */;
          margin-left: .32rem /* 16/50 */;
        }
      }
    }
  }

  .search-body {
    box-sizing: border-box;
    // padding: .32rem /* 16/50 */ .12rem /* 6/50 */;
    padding: .3rem /* 16/50 */ .2rem /* 6/50 */;
    .search-history {
      margin: 0 0 .32rem /* 16/50 */;
      & > p {
        font-size: .28rem /* 14/50 */;
        .list(row);
        // display: flex;
        line-height: .44rem /* 22/50 */;
        justify-content: space-between;
        align-items: center;
        em {
          font-size: .44rem /* 22/50 */;
        }
      }
      & > ul {
        .list(row);
        // display: flex;
        font-size: .24rem /* 12/50 */;
        flex-wrap: wrap;
        li {
          background-color: #fff;
          padding: .12rem /* 6/50 */ .2rem /* 10/50 */;
          line-height: .32rem /* 16/50 */;
          letter-spacing: 1px;
          border-radius: .32rem /* 16/50 */;
          margin-top: .24rem /* 12/50 */;
          margin-right: .16rem /* 8/50 */;
        }
      }
      .van-row {
        .van-col {
          background-color: #f0f0f0;
          font-size: .28rem /* 14/50 */;
          text-align: center;
          border-radius: .32rem /* 16/50 */;
          padding: .12rem /* 6/50 */;
        }
      }
    }
    .search-goods {
      .van-card {
        background-color: #fff;
        margin-bottom: .2rem;
        // padding: 5px;
        border-radius: .2rem /* 3/50 */;
        box-shadow: 0px 0px .04rem 0px rgba(0, 0, 0, 0.02);
        &:not(:first-child) {
          margin-top: 3px;
        }
        .van-card__desc {
          font-size: .22rem /* 11/50 */;
          letter-spacing: 1px;
          color: #bcbcbc;
        }
        .van-card__thumb {
          width: 2.5rem;
          height: 2.5rem;
          .van-image {
            /deep/ .van-image__img {
              border-radius: .2rem /* 3/50 */;
            }
          }
        }
        .van-card__content {
          box-sizing: border-box;
          padding: .12rem /* 6/50 */;
          .van-card__title {
            max-height: 50px;
            line-height: .36rem /* 19/50 */;
            font-size: .28rem /* 14/50 */;
            color: black;
            margin: .18rem .4rem 0.18rem 0rem /* 16/50 */;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
          }
        }
      }
    }
  }
}
/deep/ .van-field__left-icon{
  position: absolute;
  top:0.1rem;
  right: 0rem;
  .van-icon-search::before{
    font-size: .36rem;
    color:#BFBFBF
  }
}
.van-search__content--round{
  padding: .06rem .3rem;
  height: .7rem;
  background: #F6F6F9;
  border-radius: .2rem;
  box-sizing: border-box;
}
.van-card{
  padding: 0;
}
.van-card__desc{
  margin-bottom: .38rem;
  font-size: .22rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: .3rem;
}
/deep/ .van-card__price{
    font-size: .36rem !important;
    font-family: SF Pro Text;
    font-weight: 500;
    color: @price_text_color !important;
    line-height: .24rem;
}
/deep/ .van-field__value{
  // padding-left: .5rem;
}
/deep/.van-field__clear{
  display: none;
}
</style>
