<template>
  <div class="fast">
    <p class="iconfont icon-gengduo2" style="font-size:.4rem" @click="openPopup"></p>
    <!-- 功能直达 -->
    <van-popup
      v-model="showPopup"
      round
      position="top"
      :closeable="true"
      class="direct-func"
      :overlay-style="{'background-color':'rgba(0,0,0,0)'}"
    >
      <h1>功能直达</h1>
      <ul class="func-list">
        <li v-for="(direct,index) in directFunc" :key="index" @click="goDirectFunc(direct.path)">
          <span class="iconfont" :class="direct.iconName"></span>
          <p>{{direct.txtName}}</p>
        </li>
      </ul>
    </van-popup>
  </div>
</template>

<script>
import { Popup } from 'vant';
export default {
  data() {
    return {
      showPopup: false,
      directFunc: [
        {
          iconName: 'icon-51',
          txtName: '首页',
          path: '/home'
        },

        {
          iconName: 'icon-gouwuche1',
          txtName: '购物车',
          path: '/shoppingcar'
        },
        {
          iconName: 'icon-classify_icon',
          txtName: '分类',
          path: '/classfication'
        },
        // {
        //   iconName: 'icon-kefu',
        //   txtName: '客服',
        //   path: '/customerChat'
        // },
        {
          iconName: 'icon-zuji',
          txtName: '足迹',
          path: '/footprint'
        }
      ]
    };
  },
  components: {
    [Popup.name]: Popup
  },
  methods: {
    goDirectFunc(path) {
      this.$router.replace(path);
    },
    // 打开功能直达
    openPopup() {
      this.showPopup = true;
    }
  }
};
</script>

<style lang="less" scoped>
.fast {
  .direct-func {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    h1 {
      padding: .32rem /* 16/50 */ .32rem /* 16/50 */ 0;
      letter-spacing: 2px;
    }
    // 关闭按钮
    /deep/ .van-popup__close-icon {
      color: #fff;
    }
    .func-list {
      display: flex;
      color: #fff;
      align-items: center;
      font-size: .22rem /* 11/50 */;
      flex-wrap: wrap;
      padding: .32rem /* 16/50 */;
      justify-content: space-between;
      li {
        width: 70px;
        height: 80px;
        background-color: rgba(0, 0, 0, 0.65);
        box-sizing: border-box;
        margin-bottom: .32rem /* 16/50 */;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: normal;
        border-radius: .16rem /* 8/50 */;
        span {
          font-size: .4rem /* 20/50 */;
        }
        p {
          margin-top: .16rem /* 8/50 */;
        }
      }
    }
  }
}
</style>